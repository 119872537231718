function initScroll() {
  new SmoothScroll(document, 20, 12);
}

function SmoothScroll(target, speed, smooth) {
  // handling clicks init
  var elementIsClicked = false;

  function checkIfScrolled(e) {
    if (e.code === 38 || e.code === 40) {
      clickHandler();
    }
  }
  function clickHandler() {
    elementIsClicked = true;
  }
  var aboutElement = document.getElementById("click_about");
  if (aboutElement) {
    aboutElement.addEventListener("click", clickHandler);
  }

  var workElement = document.getElementById("click_work");
  if (workElement) {
    workElement.addEventListener("click", clickHandler);
  }

  document.addEventListener("keypress", checkIfScrolled);

  if (target === document)
    target =
      document.scrollingElement ||
      document.documentElement ||
      document.body.parentNode ||
      document.body; // cross browser support for document scrolling

  var moving = false;
  var pos = target.scrollTop;

  var frame =
    target === document.body && document.documentElement
      ? document.documentElement
      : target; // safari is the new IE

  if (elementIsClicked) {
    target.scrollIntoView({ behaviour: "smooth" });
  }

  target.addEventListener("mousewheel", scrolled, { passive: false });
  target.addEventListener("DOMMouseScroll", scrolled, { passive: false });

  function scrolled(e) {
    e.preventDefault(); // disable default scrolling

    var delta = normalizeWheelDelta(e);

    pos += -delta * speed;
    pos = Math.max(0, Math.min(pos, target.scrollHeight - frame.clientHeight)); // limit scrolling

    if (!moving) {
      if (elementIsClicked == true) {
        updateOnClick();
      } else {
        update();
      }
    }
  }

  function normalizeWheelDelta(e) {
    if (e.detail) {
      if (e.wheelDelta)
        return (e.wheelDelta / e.detail / 40) * (e.detail > 0 ? 1 : -1);
      // Opera
      else return -e.detail / 3; // Firefox
    } else return e.wheelDelta / 120; // IE,Safari,Chrome
  }

  function updateOnClick() {
    moving = true;
    var aboutElement = document.getElementById("click_about");
    aboutElement.addEventListener("click", clickHandler);
    if (elementIsClicked == true) {
      elementIsClicked = false;
      pos = target.scrollTop + 1;
      var delta = (pos - target.scrollTop) / smooth;
      target.scrollTop += delta;
      if (Math.abs(delta) > 0.5) requestFrame(update);
      else moving = false;
    }
  }

  function update() {
    moving = true;
    var difference = function (a, b) {
      return Math.abs(a - b);
    };
    var dif = difference(pos, target.scrollTop);
    var delta = (pos - target.scrollTop) / smooth;
    target.scrollTop += delta;
    if (Math.abs(delta) > 0.5) requestFrame(update);
    else moving = false;
  }

  var requestFrame = (function () {
    // requestAnimationFrame cross browser
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function (func) {
        window.setTimeout(func, 1000 / 50);
      }
    );
  })();
}

export { initScroll, SmoothScroll };
