import React from "react";
import Animate from "../../../hooks/use-animate";

const HeroSection = () => {
  return (
    <div className="l-hero-section">
      <Animate triggerOnce>
        <p className="l-hero-section-text">
          Front-end Engineer with
          <span className="italic mx-10">7 years of experience.</span>
          Expertise in web performance optimizations.
        </p>
        <p className="l-hero-section-text l-hero-section-text-bottom">
          Currently working as a
          <span className="italic mx-10">Software Engineer @Google.</span>
          Previously worked with
          <span className="italic mx-10">
            Cars24 - an India based unicorn startup.
          </span>
        </p>
        <p className="l-hero-section-scroll-down">Scroll down</p>
      </Animate>
    </div>
  );
};

export default HeroSection;
