import React from "react";
import Animate from "../../../hooks/use-animate";
import { CALENDLY_URL } from "../../../constants/url-contants";
import Button from "../../shared/button";

const InfoSticky = () => {
  return (
    <div className="l-work-info">
      <Animate cascade triggerOnce>
        <p className="l-work-info-heading">Selected Work</p>
        <p className="l-work-info-details">
          Software Engineer at Google, working in Google Pay team. Previously
          lead the Frontend team at Cars24, UAE.
          <br />
          <br />
          Agressive problem solver dedicated to producing results.
        </p>

        <a href={CALENDLY_URL} target="_blank">
          <Button
            text={"Schedule A Call With Me"}
            className="l-work-info-call-btn"
          />
        </a>
      </Animate>
    </div>
  );
};

export default InfoSticky;
