import React from "react";
import { useParams } from "react-router-dom";
import { BLOGS_LIST_MAP, SCREEN_TYPES } from "../../../constants/app-constants";
import BlogContentParser from "../../../utils/blog-content-parser";

const Blog = () => {
  const params = useParams();
  const { blogId } = params;

  const { name: blogName, content } = BLOGS_LIST_MAP[blogId];
  return (
    <div className="m-blog-container">
      <BlogContentParser screenType={SCREEN_TYPES.MOBILE} content={content} />
    </div>
  );
};

export default Blog;
