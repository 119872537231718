import React, { useState } from "react";
import { NAVBAR_ITEMS } from "../../../constants/app-constants";
import { CALENDLY_URL } from "../../../constants/url-contants";
import LogoWebDev from "../../../assets/images/logo-webdev.svg";
import MenuBtn from "../../../assets/images/menu-btn.svg";
import MenuCloseBtn from "../../../assets/images/menu_close.svg";
import Button from "../../shared/button";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const handleNavbarClick = (e, item) => {
    setShowMenu(false);
    const { isExternal, link, id } = item;
    if (isExternal) {
      e.preventDefault();
      window.open(link, "_blank");
    } else {
      const section = document.querySelector(`#${id}`);
      window.setTimeout(() => {
        section.scrollIntoView({ behavior: "smooth" });
      }, 200);
    }
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="m-navbar">
      <div className="m-navbar-left">
        <img className="m-navbar-logo" src={LogoWebDev} />
        <p className="m-navbar-logo-text">Sidhanshu Monga</p>
      </div>
      <div className="m-navbar-right">
        <img className="m-navbar-menu" src={MenuBtn} onClick={toggleMenu} />
      </div>
      <div
        className={`m-navbar-modal ${
          showMenu ? "m-navbar-modal-show" : "m-navbar-modal-hidden"
        }`}
      >
        <aside className="m-navbar-items-container">
          <img
            className="m-navbar-menu-close"
            src={MenuCloseBtn}
            onClick={toggleMenu}
          />
          {NAVBAR_ITEMS.map((item, idx) => (
            <p
              id={`click_${item.id}`}
              key={`label_${idx}`}
              className="m-navbar-items"
              onClick={(e) => handleNavbarClick(e, item)}
            >
              {item.label}
            </p>
          ))}
          <a href={CALENDLY_URL} target="_blank">
            <Button text={"Schedule A Call"} className="m-navbar-btn" />
          </a>
        </aside>
      </div>
    </div>
  );
};

export default Navbar;
