import React from "react";
import CompanyIcon1 from "../../../assets/images/company-icon-1.png";
import CompanyIcon2 from "../../../assets/images/company-icon-2.png";
import CompanyIcon3 from "../../../assets/images/company-icon-3.png";
import CompanyIcon4 from "../../../assets/images/company-icon-4.png";
import Animate from "../../../hooks/use-animate";

const CompaniesList = () => {
  return (
    <Animate cascade triggerOnce>
      <div className="l-companies-list-container">
        <img src={CompanyIcon4} />
        <img src={CompanyIcon3} />
        <img src={CompanyIcon2} />
        <img src={CompanyIcon1} />
      </div>
    </Animate>
  );
};

export default CompaniesList;
