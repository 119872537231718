import React from "react";
import { JOURNEY_TAGS } from "../../../constants/app-constants";
import ChipButton from "../../shared/chip-button";
import Animate from "../../../hooks/use-animate";

const Header = () => {
  return (
    <div className="l-journey-header">
      <Animate cascade triggerOnce>
        <p className="l-journey-header-text">Professional Journey</p>
        <div className="l-journey-header-tags">
          {JOURNEY_TAGS.map((item) => (
            <ChipButton key={`journey_tag_${item}`} isDark text={item} />
          ))}
        </div>
      </Animate>
    </div>
  );
};

export default Header;
