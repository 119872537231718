import React from "react";
import { ABOUT_USP } from "../../../constants/app-constants";
import UspTile from "./usp-tile";

const AboutUsp = () => {
  return (
    <div className="m-about-usp-container">
      {ABOUT_USP.map((item, idx) => (
        <UspTile key={`about_usp_${idx}`} {...item} />
      ))}
    </div>
  );
};

export default AboutUsp;
