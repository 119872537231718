import React from "react";
import HeroSection from "./hero-section";
import Navbar from "./navbar";
import CompaniesList from "./companies-list";

const Landing = () => {
  return (
    <div className="l-landing-container">
      <Navbar />
      <HeroSection />
      <CompaniesList />
    </div>
  );
};

export default Landing;
