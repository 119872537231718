import React from "react";
import PropTypes from "prop-types";
import Project1 from "../../../assets/images/project-1.svg";
import Project2 from "../../../assets/images/project-2.svg";
import Project3 from "../../../assets/images/project-3.svg";
import Animate from "../../../hooks/use-animate";

const PROJECT_IMG_MAP = {
  Project1: Project1,
  Project2: Project2,
  Project3: Project3,
};

const ProjectCard = ({ heading, details, tags, img }) => {
  return (
    <div className="m-work-project-card">
      <img className="m-work-project-img" src={PROJECT_IMG_MAP[img]} />
      <Animate triggerOnce>
        <p className="m-work-project-heading">{heading}</p>
        <div className="m-work-project-details-container">
          {details.map((item, idx) => (
            <div
              key={`project_detail_${idx}`}
              className="m-work-project-details-row"
            >
              <p className="m-work-project-details">{item.text}</p>
            </div>
          ))}
        </div>
        <div className="m-work-project-tags-container">
          {tags.map((item, idx) => (
            <div key={`project_tag_${idx}`} className="m-work-project-tags">
              <p className="m-work-project-tags-text">{item}</p>
            </div>
          ))}
        </div>
      </Animate>
    </div>
  );
};

ProjectCard.propTypes = {
  heading: PropTypes.string,
  details: PropTypes.array,
  tags: PropTypes.array,
  img: PropTypes.string,
};

export default ProjectCard;
