import React from "react";
import PropTypes from "prop-types";
import { SIZE_PREFIX } from "../constants/app-constants";

const CONTENT_TYPES = {
  h: "h", // heading
  sh: "sh", // sub-heading
  ssh: "ssh", // sub-sub-heading
  q: "q", // quote
  sq: "sq", // sub-quote
  p: "p", // paragraph
  p: "ip", // italic-paragraph
  sp: "sp", // sub-paragraph
  i: "i", // image
  ul: "ul", // unordered-list
  ol: "ol", // ordered-list
};

const createMarkup = (text) => {
  return { __html: text };
};

const getClassNameWithPrefix = (screenType, className, cType) =>
  `${SIZE_PREFIX[screenType]}-${className}${cType ? `-${cType}` : ""}`;

const ImageTemplate = ({ type, data, screenType, properties }) => {
  return (
    <div
      className={getClassNameWithPrefix(screenType, "blog-img-container", type)}
    >
      <img
        className={getClassNameWithPrefix(screenType, "content", type)}
        src={data}
        style={properties}
      />
    </div>
  );
};

const ListTemplate = ({ type, data, screenType }) => {
  return type === CONTENT_TYPES.ul ? (
    <ul className={getClassNameWithPrefix(screenType, "content", type)}>
      {data.map((l, ind) => (
        <li key={`ul-li-${ind}`} dangerouslySetInnerHTML={createMarkup(l)} />
      ))}
    </ul>
  ) : (
    <ol className={getClassNameWithPrefix(screenType, "content", type)}>
      {data.map((l, ind) => (
        <li key={`ol-li-${ind}`} dangerouslySetInnerHTML={createMarkup(l)} />
      ))}
    </ol>
  );
};

const Template = ({ type, data, screenType, properties }) => {
  if (type === CONTENT_TYPES.i) {
    return (
      <ImageTemplate
        type={type}
        data={data}
        properties={properties}
        screenType={screenType}
      />
    );
  } else if (type === CONTENT_TYPES.ul || type === CONTENT_TYPES.ol) {
    return <ListTemplate type={type} data={data} screenType={screenType} />;
  }
  return (
    <div
      className={getClassNameWithPrefix(screenType, "content", type)}
      dangerouslySetInnerHTML={createMarkup(data)}
    />
  );
};

const BlogContentParser = ({ screenType, content }) => {
  return (
    <div
      className={getClassNameWithPrefix(screenType, "blog-content-container")}
    >
      {content.map((c, idx) => (
        <Template key={`${c.type}-${idx}`} {...c} screenType={screenType} />
      ))}
    </div>
  );
};

BlogContentParser.propTypes = {
  screenType: PropTypes.string,
  content: PropTypes.array,
};

export default BlogContentParser;
