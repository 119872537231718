import React from "react";
import { Routes, Route } from "react-router-dom";
import MobileWeb from "../components/mobile";
import Blogs from "../components/mobile/blogs";
import Blog from "../components/mobile/blogs/blog";

const Mobile = () => {
  return (
    <Routes>
      <Route path="/" element={<MobileWeb />} />
      <Route path="blogs" element={<Blogs />}>
        <Route path=":blogId" element={<Blog />} />
      </Route>
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Routes>
  );
};

export default Mobile;
