import React from "react";
import { Fade } from "react-awesome-reveal";

const Animate = ({ children, ...restProps }) => {
  return (
    <Fade direction="up" {...restProps}>
      {children}
    </Fade>
  );
};

export default Animate;
