import React from "react";
import Animate from "../../../hooks/use-animate";

const InfoSticky = () => {
  return (
    <div className="m-work-info">
      <Animate cascade triggerOnce>
        <p className="m-work-info-heading">Selected Work</p>
        <p className="m-work-info-details">
          Software Engineer at Google, working in Google Pay team. Previously
          lead the Frontend team at Cars24, UAE.
          <br />
          <br />
          Agressive problem solver dedicated to producing results.
        </p>
      </Animate>
    </div>
  );
};

export default InfoSticky;
