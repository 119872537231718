import React from "react";
import Header from "./header";
import PathSection from "./path-section";
import WorkSeparator from "../../../assets/images/work-separator.svg";
import SectionSeparator from "../../shared/separator";

const Journey = () => {
  return (
    <React.Fragment>
      <SectionSeparator src={WorkSeparator} srcHeight={24} />
      <div className="m-journey-container">
        <Header />
        <PathSection />
      </div>
    </React.Fragment>
  );
};

export default Journey;
