import React from "react";
import { Link, Outlet } from "react-router-dom";
import { BLOGS_IDS_LIST } from "../../../constants/app-constants";

const BlogList = () => {
  return (
    <div className="l-blog-list">
      <div style={{ display: "flex" }}>
        <nav
          style={{
            borderRight: "solid 1px",
            padding: "1rem",
          }}
        >
          {BLOGS_IDS_LIST.map((blog) => (
            <Link
              style={{ display: "block", margin: "1rem 0" }}
              to={`/blogs/${blog.id}`}
              key={blog.id}
            >
              {blog.name}
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default BlogList;
