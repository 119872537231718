import React from "react";
import Connect from "./connect";
import Footer from "./footer";

const Contact = () => {
  return (
    <div className="m-contact-container">
      <Connect />
      <Footer />
    </div>
  );
};

export default Contact;
