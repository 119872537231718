import React from "react";
import { PROJECTS_LIST } from "../../../constants/app-constants";
import ProjectCard from "./project-card";

const WorkProjects = () => {
  return (
    <div className="m-work-projects">
      {PROJECTS_LIST.map((item, idx) => (
        <ProjectCard {...item} key={`work_project_item_${idx}`} />
      ))}
    </div>
  );
};

export default WorkProjects;
