import React from "react";
import { CALENDLY_URL } from "../../../constants/url-contants";
import Animate from "../../../hooks/use-animate";
import Button from "../../shared/button";

const Connect = () => {
  return (
    <div className="m-contact-connect">
      <Animate cascade triggerOnce>
        <p className="m-contact-connect-text">sidhanshumonga28@gmail.com</p>
        <div>
          <Button text={"Email"} className="m-connect-btn-filled" />
          <a href={CALENDLY_URL} target="_blank">
            <Button text={"Schedule A Call"} className="m-connect-btn" />
          </a>
        </div>
      </Animate>
    </div>
  );
};

export default Connect;
