import React, { useRef, useState } from "react";
import ArrowRight from "../../../assets/images/arrow-right-black.svg";
import ArrowLeft from "../../../assets/images/arrow-left-gray.svg";
import { TESTIMONIALS } from "../../../constants/app-constants";
import TestimonialTile from "./testimonial-tile";
import Animate from "../../../hooks/use-animate";
import Slider from "react-slick";

const Testimonials = () => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0); // if currentScreen is 1 then [1,2] are displayed on UI.
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    afterChange: (current) => setCurrentSlide(current),
  };

  const goNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const goPrevSlide = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <div className="m-about-testimonials-container">
      <Animate cascade triggerOnce>
        <p className="m-about-testimonials-heading">Recommendations</p>
        <div className="m-about-testimonials-details-section">
          <p className="m-about-testimonials-details">
            {/* I am very helped in making a website and business development that
            makes the product that I have to have a quality for use by the user */}
          </p>
        </div>
      </Animate>
      <Animate triggerOnce direction="right">
        <div className="m-about-testimonials-cards-section">
          <Slider {...settings} ref={sliderRef}>
            {TESTIMONIALS.map((item, idx) => (
              <TestimonialTile key={`testTile_${idx}`} {...item} />
            ))}
          </Slider>
        </div>
        <div className="m-about-testimonials-arrows">
          <img
            src={currentSlide === 0 ? ArrowLeft : ArrowRight}
            onClick={goPrevSlide}
            className={currentSlide === 0 ? "" : "reverse-arrows"}
          />
          <img
            src={currentSlide < 3 ? ArrowRight : ArrowLeft}
            onClick={goNextSlide}
            className={currentSlide < 3 ? "" : "reverse-arrows"}
          />
        </div>
      </Animate>
    </div>
  );
};

export default Testimonials;
