import React from "react";
import About from "./about";
import Contact from "./contact";
import Journey from "./journey";
import Landing from "./landing";
import Work from "./work";

const DesktopWeb = () => {
  return (
    <React.Fragment>
      <Landing />
      <Work />
      <Journey />
      <About />
      <Contact />
    </React.Fragment>
  );
};

export default DesktopWeb;
