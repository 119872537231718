import React from "react";
import { PAST_WORK } from "../../../constants/app-constants";
import Animate from "../../../hooks/use-animate";
import WorkTile from "./work-tile";

const PathSection = () => {
  const initialMargin = -5;
  const marginForPathDots = 100 / (PAST_WORK.length - 1);
  return (
    <div className="l-journey-path">
      {PAST_WORK.map((item, idx) => {
        const isRightAligned = idx % 2 !== 0;
        return (
          <div
            key={`work_tile_${item.title}`}
            className={`l-journey-path-sections ${
              isRightAligned ? "" : "l-journey-path-sections-right"
            }`}
          >
            <Animate cascade triggerOnce>
              <WorkTile {...item} isRightAligned={isRightAligned} />
            </Animate>
          </div>
        );
      })}
      <div
        className="l-journey-path-line"
        style={{ width: `${126 * PAST_WORK.length}px` }}
      >
        {new Array(PAST_WORK.length).fill(0).map((_i, idx) => {
          const margin =
            idx === 0
              ? `${initialMargin}%`
              : `${idx * marginForPathDots + initialMargin}%`;
          return (
            <div
              key={`path_dots_${idx}`}
              className={`l-journey-path-line-dots l-journey-path-line-dots-${
                idx + 1
              }`}
              style={{
                left: margin,
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default PathSection;
