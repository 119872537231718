import React from "react";
import PropTypes from "prop-types";
import Animate from "../../../hooks/use-animate";
import UspBottomLine from "../../../assets/images/about-usp-underline.svg";

const UspTile = ({ heading, tags }) => {
  return (
    <div className="m-about-usp-card-outer">
      <div className="m-about-usp-card-inner-div">
        <p className="m-about-usp-card-heading">{heading}</p>
        <div className="m-about-usp-card-tags-container">
          {tags.map((item, idx) => (
            <div key={`tag_${item}_${idx}`} className="m-about-usp-card-tags">
              {item}
            </div>
          ))}
        </div>
      </div>

      <img
        src={UspBottomLine}
        width="100%"
        style={{ position: "absolute", bottom: 0 }}
      />
    </div>
  );
};

UspTile.propTypes = {
  heading: PropTypes.string,
  tags: PropTypes.array,
};

export default UspTile;
