import React from "react";
import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";
import {
  SCREEN_SIZES,
  SCREEN_TYPES,
  SIZE_PREFIX,
} from "../constants/app-constants";

const ScreenProvider = ({ screens }) => {
  const isDesktop = useMediaQuery(SCREEN_SIZES.DESKTOP);
  const isTablet = useMediaQuery(SCREEN_SIZES.TABLET);
  const isMobile = useMediaQuery(SCREEN_SIZES.MOBILE);

  const CURRENT_SCREEN = isDesktop
    ? SCREEN_TYPES.DESKTOP
    : isTablet
    ? SCREEN_TYPES.TABLET
    : isMobile
    ? SCREEN_TYPES.MOBILE
    : SCREEN_TYPES.DEFAULT;

  const Component = screens[CURRENT_SCREEN];
  return <Component sPrefix={SIZE_PREFIX[CURRENT_SCREEN]} />;
};

ScreenProvider.propTypes = {
  screens: PropTypes.object.isRequired,
};

export default ScreenProvider;
