import React from "react";
import PropTypes from "prop-types";
import GurmitPic from "../../../assets/images/gurmit_pic.jpeg";
import AditiPic from "../../../assets/images/aditi_pic.jpeg";

const IMG_MAP = {
  person1: GurmitPic,
  person2: AditiPic,
  person3: GurmitPic,
  person4: AditiPic,
};

const TestimonialTile = ({ text, person }) => {
  const { name, profile, img } = person || {};
  return (
    <div className="m-about-testimonials-card">
      <p className="m-about-testimonials-card-text">{text}</p>
      <div className="m-about-testimonials-person-section">
        <img className="m-about-testimonials-person-img" src={IMG_MAP[img]} />
        <div className="m-about-testimonials-person-details">
          <p className="m-about-testimonials-person-text">{name}</p>
          <p className="m-about-testimonials-person-profile">{profile}</p>
        </div>
      </div>
    </div>
  );
};

TestimonialTile.propTypes = {
  text: PropTypes.string,
  person: PropTypes.object,
};

export default TestimonialTile;
