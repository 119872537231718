import React from "react";
import { Routes, Route } from "react-router-dom";
import DesktopWeb from "../components/desktop";
import Blogs from "../components/desktop/blogs";
import Blog from "../components/desktop/blogs/blog";

const Desktop = () => {
  return (
    <Routes>
      <Route path="/" element={<DesktopWeb />} />
      <Route path="blogs" element={<Blogs />}>
        <Route path=":blogId" element={<Blog />} />
      </Route>
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Routes>
  );
};

export default Desktop;
