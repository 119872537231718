import React from "react";
import InfoSticky from "./info-sticky";
import WorkProjects from "./work-projects";
import LandingSeparator from "../../../assets/images/landing-separator.png";
import SectionSeparator from "../../shared/separator";

const Work = () => {
  return (
    <React.Fragment>
      <div id="work">
        <SectionSeparator src={LandingSeparator} srcHeight={24} />
      </div>
      <div className="m-work-container">
        <InfoSticky />
        <WorkProjects />
      </div>
    </React.Fragment>
  );
};

export default Work;
