import React from "react";
import { SOCIAL_MEDIA_LINKS } from "../../../constants/app-constants";
import Animate from "../../../hooks/use-animate";

const BlogSpaceHeader = () => {
  return (
    <Animate cascade triggerOnce>
      <div className="m-blog-header">Blog list header</div>
    </Animate>
  );
};

export default BlogSpaceHeader;
