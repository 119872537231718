import React from "react";
import { PAST_WORK } from "../../../constants/app-constants";
import Animate from "../../../hooks/use-animate";
import WorkTile from "./work-tile";

const PathSection = () => {
  return (
    <div className="m-journey-path">
      {PAST_WORK.map((item, idx) => {
        return (
          <div
            key={`work_tile_${item.title}`}
            className={`m-journey-path-sections`}
          >
            <Animate cascade triggerOnce>
              <WorkTile {...item} />
            </Animate>
          </div>
        );
      })}
    </div>
  );
};

export default PathSection;
