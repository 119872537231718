import React, { useLayoutEffect } from "react";
import { SCREEN_TYPES } from "./constants/app-constants";
import { initScroll } from "./hooks/use-scroll";
import ScreenProvider from "./providers/ScreenProvider";
import { Desktop, Mobile, Tablet } from "./screens";
import "./styles/index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SCREEN_COMPONENTS = {
  [SCREEN_TYPES.DESKTOP]: Desktop,
  [SCREEN_TYPES.TABLET]: Mobile,
  [SCREEN_TYPES.MOBILE]: Mobile,
  [SCREEN_TYPES.DEFAULT]: Desktop,
};

function App() {
  useLayoutEffect(() => {
    initScroll();
  }, []);
  return <ScreenProvider screens={SCREEN_COMPONENTS} />;
}

export default App;
