import React from "react";
import BlogList from "./blog-list";
import BlogSpaceHeader from "./blog-space-header";
import { useParams, Outlet } from "react-router-dom";

const Blogs = () => {
  let params = useParams();
  let { blogId } = params;
  return (
    <div className="m-blogs-container">
      {!blogId && (
        <React.Fragment>
          <BlogSpaceHeader />
          <BlogList />
        </React.Fragment>
      )}
      <Outlet />
    </div>
  );
};

export default Blogs;
