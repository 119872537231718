import React from "react";
import PropTypes from "prop-types";

const SectionSeparator = ({ src, srcHeight }) => {
  return (
    <div className="l-section-separator" style={{ height: `${srcHeight}px` }}>
      <img src={src} className="l-section-separator-img" />
    </div>
  );
};

SectionSeparator.propTypes = {
  src: PropTypes.any,
  srcHeight: PropTypes.number,
};

export default SectionSeparator;
