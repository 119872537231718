import React from "react";
import PropTypes from "prop-types";

const WorkTile = ({ title, subTitle, details, isRightAligned, isPresent }) => {
  return (
    <div className={`l-work-tile ${isRightAligned ? "l-work-tile-right" : ""}`}>
      <p
        className={
          isPresent ? "l-work-tile-heading-present" : "l-work-tile-heading"
        }
      >
        {title}
      </p>
      <p
        className={
          isPresent
            ? "l-work-tile-sub-heading-present"
            : "l-work-tile-sub-heading"
        }
      >
        {subTitle}
      </p>
      <p className="l-work-tile-details">{details}</p>
    </div>
  );
};

WorkTile.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  details: PropTypes.string,
  isRightAligned: PropTypes.bool,
  isPresent: PropTypes.bool,
};

export default WorkTile;
