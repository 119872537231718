import React from "react";
import { SOCIAL_MEDIA_LINKS } from "../../../constants/app-constants";
import Animate from "../../../hooks/use-animate";

const Footer = () => {
  return (
    <Animate cascade triggerOnce>
      <div className="l-contact-footer">
        <p className="l-contact-sm-madeby">
          Designed by{" "}
          <a
            href="https://www.linkedin.com/in/pargat-mann-bba529112/"
            target="_blank"
            className="l-contact-sm-madeby-name"
          >
            Pargat Mann
          </a>
        </p>
        {SOCIAL_MEDIA_LINKS.map((item) => (
          <a
            key={item.id}
            href={item.url}
            target="_blank"
            className="l-contact-sm-links mx-20"
          >
            {item.title}
          </a>
        ))}
        <p className="l-contact-sm-links mx-20">©️ all copyright reserved</p>
      </div>
    </Animate>
  );
};

export default Footer;
