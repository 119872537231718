import React from "react";
import AboutInfo from "./about-info";
import AboutSlider from "./about-slider";
import AboutUsp from "./about-usp";
import Testimonials from "./testimonials";

const About = () => {
  return (
    <React.Fragment>
      <div className="m-about-container" id="about">
        <AboutInfo />
      </div>
      {/* <AboutSlider /> */}
      <div className="m-about-container-2">
        <AboutUsp />
        <Testimonials />
      </div>
    </React.Fragment>
  );
};

export default About;
