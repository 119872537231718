import React from "react";
import { NAVBAR_ITEMS } from "../../../constants/app-constants";
import { CALENDLY_URL } from "../../../constants/url-contants";
import LogoWebDev from "../../../assets/images/logo-webdev.svg";
import Button from "../../shared/button";
import { SmoothScroll } from "../../../hooks/use-scroll";

const Navbar = () => {
  const handleNavbarClick = (e, item) => {
    const { isExternal, link, id } = item;
    if (isExternal) {
      e.preventDefault();
      window.open(link, "_blank");
    } else {
      const section = document.querySelector(`#${id}`);
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="l-navbar">
      <div className="l-navbar-left">
        <img className="l-navbar-logo" src={LogoWebDev} />
        <p className="l-navbar-logo-text">Sidhanshu Monga</p>
      </div>
      <div className="l-navbar-right">
        {NAVBAR_ITEMS.map((item, idx) => (
          <p
            id={`click_${item.id}`}
            key={`label_${idx}`}
            className="l-navbar-items"
            onClick={(e) => handleNavbarClick(e, item)}
          >
            {item.label}
          </p>
        ))}
        <a href={CALENDLY_URL} target="_blank">
          <Button text={"Schedule A Call"} className="l-navbar-btn" />
        </a>
      </div>
    </div>
  );
};

export default Navbar;
