import React from "react";
import PropTypes from "prop-types";

const ChipButton = ({ text, isDark }) => {
  return (
    <div className={isDark ? "chip-btn-dark" : "chip-btn"}>
      <p className={isDark ? "chip-btn-dark-text" : "chip-btn-text"}>{text}</p>
    </div>
  );
};

ChipButton.propTypes = {
  isDark: PropTypes.bool,
  text: PropTypes.string,
};

export default ChipButton;
