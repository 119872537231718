import React from "react";
import PropTypes from "prop-types";

const WorkTile = ({ title, subTitle, details, isRightAligned, isPresent }) => {
  return (
    <div className={`m-work-tile ${isRightAligned ? "m-work-tile-right" : ""}`}>
      <div className="m-journey-path-line-dots-container">
        <div
          className={`m-journey-path-line-dots ${
            isPresent ? "m-journey-path-line-dots-present" : ""
          }`}
        ></div>
      </div>
      <div>
        <p
          className={
            isPresent ? "m-work-tile-heading-present" : "m-work-tile-heading"
          }
        >
          {title}
        </p>
        <p
          className={
            isPresent
              ? "m-work-tile-sub-heading-present"
              : "m-work-tile-sub-heading"
          }
        >
          {subTitle}
        </p>
        <p className="m-work-tile-details">{details}</p>
      </div>
    </div>
  );
};

WorkTile.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  details: PropTypes.string,
  isRightAligned: PropTypes.bool,
  isPresent: PropTypes.bool,
};

export default WorkTile;
