import React from "react";
import AboutImg from "../../../assets/images/about-img.png";
import { ABOUT_CHIPS } from "../../../constants/app-constants";
import Animate from "../../../hooks/use-animate";
import ChipButton from "../../shared/chip-button";

const AboutInfo = () => {
  return (
    <div className="m-about-details">
      <div className="m-about-info">
        <Animate triggerOnce>
          <p className="m-about-info-heading">About Me</p>
        </Animate>
      </div>
      <div className="m-about-info-details">
        <div className="m-about-info-details-container">
          <Animate cascade triggerOnce>
            <p className="m-about-info-details-text">
              <div className="m-about-details-img-text-wrapper">
                <p className="m-about-details-img-text">
                  Software Engineer at Google, working in Google Pay team.
                  Previously lead the Frontend team at Cars24, UAE.
                </p>
                <div className="m-about-details-img">
                  <img src={AboutImg} />
                </div>
              </div>
              Agressive problem solver dedicated to producing results.
            </p>
            <div className="m-about-info-chips">
              {ABOUT_CHIPS.map((item, idx) => (
                <ChipButton key={`about_chip_${idx}`} text={item} />
              ))}
            </div>
            <p className="m-about-info-details-text">
              Have been mentoring juniors and college students since 2019.
              Taking 1:1 sessions, seminars and organising bootcamps for
              preparing students for interviews.
            </p>
          </Animate>
        </div>
        {/* <div className="m-about-details-img">
          <Animate triggerOnce>
            <img src={AboutImg} />
          </Animate>
        </div> */}
      </div>
    </div>
  );
};

export default AboutInfo;
