export const SCREEN_TYPES = {
  DESKTOP: "DESKTOP",
  TABLET: "TABLET",
  MOBILE: "MOBILE",
  DEFAULT: "DEFAULT",
};

export const SCREEN_SIZES = {
  [SCREEN_TYPES.DESKTOP]: { minWidth: 1100 },
  [SCREEN_TYPES.TABLET]: { minWidth: 768, maxWidth: 1099 },
  [SCREEN_TYPES.MOBILE]: { maxWidth: 767 },
  [SCREEN_TYPES.DEFAULT]: { minWidth: 768 },
};

export const SIZE_PREFIX = {
  [SCREEN_TYPES.DESKTOP]: "l",
  [SCREEN_TYPES.TABLET]: "m",
  [SCREEN_TYPES.MOBILE]: "m",
  [SCREEN_TYPES.DEFAULT]: "d",
};

export const NAVBAR_ITEMS = [
  {
    id: "work",
    label: "Work",
    isExternal: false,
  },
  {
    id: "about",
    label: "About",
    isExternal: false,
  },
  {
    id: "blogs",
    label: "Blogs",
    isExternal: true,
    link: "../blogs/journey-to-google",
  },
  {
    id: "resume",
    label: "Resume",
    isExternal: true,
    link: "https://drive.google.com/file/d/1f8kal6l3yhFkBEj8-49Cz3rfQGP-waVf/view?usp=sharing",
  },
  {
    id: "linkedin",
    label: "LinkedIn",
    isExternal: true,
    link: "https://www.linkedin.com/in/sidhanshumonga",
  },
];

export const EXPERIENCE_START_DATE = "2017-06-01";

export const SOCIAL_MEDIA_LINKS = [
  {
    title: "Twitter",
    id: "twitter",
    url: "https://www.twitter.com/sidhanshu_",
  },
  {
    title: "GitHub",
    id: "github",
    url: "https://www.github.com/sidhanshumonga",
  },
];

export const JOURNEY_TAGS = [
  "JavaScript",
  "React",
  "Angular",
  "Vue.js",
  "SSR",
  "Electrode",
  "React Native",
  "Web Optimization",
];

export const PAST_WORK = [
  {
    title: "Software Engineer, Google",
    subTitle: "Apr, 2022 - Present",
    isPresent: true,
    details:
      "- Contributed to e2e UI revamping of the product - Migrated existing codebase to Google Material 3 - Implemented new UI features quickly",
  },
  {
    title: "Senior Software Engineer, Cars24",
    subTitle: "Apr, 2021 - Mar, 2022",
    isPresent: false,
    details:
      "- Optimized web performance & efficiency by 20% - Helped raising the light house scores above 80 - Integrated multi-lingual support with SSR",
  },
  {
    title: "SDE - II, Brick&bolt",
    subTitle: "Aug, 2020 - Feb, 2021",
    isPresent: false,
    details:
      "- Developed in-house apps to handle operations - Led SSR-integration for existing react web apps - Worked on React-native based chat application",
  },
  {
    title: "Software Engineer, Essenvia",
    subTitle: "Mar, 2019 - Aug, 2020",
    isPresent: false,
    details:
      "- Build core product user-interface from scratch - Migrated codebase from Angular 6 to Angular 9 - Developed features to grow customer base",
  },
  {
    title: "Software Engineer, HISP India",
    subTitle: "Jun, 2017 - Mar, 2019",
    isPresent: false,
    details:
      "- Developed eight web apps in span of two years - Built dashboard using Vue.js for international client",
  },
];

export const PROJECTS_LIST = [
  {
    id: "project_1",
    img: "Project1",
    heading:
      "Worked on web-optimisation techniques & improved web performance by 60%",
    details: [
      {
        icon: "icon11",
        text: "⚡ Reduced TBT (total blocking time) by 80% to make website load faster,",
      },
      {
        icon: "icon12",
        text: "⏳ Focused on keeping lower time to interaction for better User Experience,",
      },
      {
        icon: "icon13",
        text: "⏱ Implemented techniques like lazy loading, tree-shaking and dynamic importing.",
      },
    ],
    tags: ["lighthouse", "lazy-loading", "web-optimization"],
  },
  {
    id: "project_2",
    img: "Project2",
    heading:
      "Zulip Mobile: An open source library for chat application (React-native)",
    details: [
      {
        icon: "icon21",
        text: "📱 Worked on react-native for around 6 months and launched hybrid mobile application,",
      },
      {
        icon: "icon22",
        text: "📍 Implemented features like sharing location, videos and documents,",
      },
      {
        icon: "icon23",
        text: "👥 Created features like managing groups and one to one messaging from scratch.",
      },
    ],
    tags: ["zulip", "chat application", "react-native"],
  },
  {
    id: "project_3",
    heading: "Data visualization - Highcharts with Vue.js",
    img: "Project3",
    details: [
      {
        icon: "icon31",
        text: "📊 Created a complex data visualisation dashboard using vue.js,",
      },
      {
        icon: "icon32",
        text: "🎛 Implemented features like changing selections & user inputs to manipulate plotted data,",
      },
      {
        icon: "icon33",
        text: "🌌 Used Highcharts for displaying data through graphs, bar, pie charts, etc.",
      },
    ],
    tags: ["vue.js", "highcharts", "data visualization"],
  },
];

export const ABOUT_CHIPS = [
  "Frontend Geek",
  "Creative",
  "Problem Solver",
  "Quick Learner",
  "Eye to detail",
];

export const ABOUT_USP = [
  {
    id: "usp_1",
    heading:
      "SIHA - AI based smart menu concept, ordering system built on Blockchain.",
    tags: ["Smart menus", "Blockchain", "Artificial Intelligence"],
  },
  {
    id: "usp_2",
    heading:
      "Eventually - App to empower small to mid-sized event managements.",
    tags: ["Python", "React native", "Event management"],
  },
  {
    id: "usp_3",
    heading:
      "1:1 Sessions- Talking about everything & anything. Connecting with people.",
    tags: ["Community", "LinkedIn", "Communication"],
  },
  {
    id: "usp_4",
    heading:
      "Frontend Bootcamp - Teaching & preparing students for Frontend Engineering.",
    tags: ["Web development", "JavaScript", "Frontend"],
  },
];

export const TESTIMONIALS = [
  {
    id: "test1",
    text: "The best example of hard-work,smart-work and a keen learner with a very humble and positive attitude. I was really impressed with his sincerity and dedication towards career. Creative, energetic, solutions oriented and highly motivated with great communication skills...",
    person: {
      img: "person1",
      name: "Gurmit Kaur Dhaliwal",
      profile: "Founder - Soft Skills Matter",
    },
  },
  {
    id: "test2",
    text: "A perfect exemplary person to be around. He posses a great combination of knowledge, skills and presence of mind and blend it all with his hard-work to yield success. He is not only a smart, enthusiastic and lively person but also never disappoint for any help...",
    person: {
      img: "person2",
      name: "Aditi Singh",
      profile: "Frontend Lead - Brick&Bolt",
    },
  },
  {
    id: "test3",
    text: "The best example of hard-work,smart-work and a keen learner with a very humble and positive attitude. I was really impressed with his sincerity and dedication towards career. Creative, energetic, solutions oriented and highly motivated with great communication skills...",
    person: {
      img: "person3",
      name: "Gurmit Kaur Dhaliwal",
      profile: "Founder - Soft Skills Matter",
    },
  },
  {
    id: "test4",
    text: "A perfect exemplary person to be around. He posses a great combination of knowledge, skills and presence of mind and blend it all with his hard-work to yield success. He is not only a smart, enthusiastic and lively person but also never disappoint for any help...",
    person: {
      img: "person4",
      name: "Aditi Singh",
      profile: "Frontend Lead - Brick&Bolt",
    },
  },
];

export const BLOGS_IDS_LIST = [
  { id: "journey-to-google", name: "Journey to Google" },
];

export const BLOGS_LIST_MAP = {
  "journey-to-google": {
    name: "Journey to Google",
    content: [
      {
        type: "h",
        data: "Journey to Google",
      },
      {
        type: "p",
        data: "I often used to tell my friends and peers that one day I would be working at Google. That one day came on 4th April 2022 when it was day one at Google. As they say, good things take time, and indeed this took time. The whole process took around 8–10 weeks, but it was worth keeping the patience.",
      },
      {
        type: "p",
        data: "I will be covering each step of this journey in detail and will be answering a few commonly asked questions that could come up in anyone’s mind while reading.",
      },
      { type: "sp" },
      { type: "sh", data: "Background" },
      {
        type: "p",
        data: "Previously, I worked with Cars24, as a Senior Software Engineer for the last year. I have around 5 years of experience and primarily it was in the Frontend domain. In those last 5 years, I applied many times through Google careers, but it was hard to get shortlisted because for numerous reasons.",
      },
      {
        type: "ul",
        data: [
          "Not maintaining a LinkedIn profile from 2017 to 2019,",
          " Not being much active in dev communities,",
          "Not having anyone to get a referral (can make a huge difference),",
          "Hard to find Frontend-focused job openings (they call it SWE frontend specialist)",
        ],
      },
      {
        type: "p",
        data: "Out of all these things, I spent the most time on the first point and got lucky with the last point. I am still not very active in dev communities and got the job without a referral.",
      },
      { type: "ssh", data: "QnA" },
      {
        type: "ul",
        data: [
          "<b>How did I improve my LinkedIn profile?</b> <br/> I started connecting with people. Interacting with them over the posts, adding them to the network. I started posting 3–4 times a week and worked on the engagement of the audience. I learned how to effectively use LinkedIn. I bought a course from Udemy and started working on my profile in an efficient manner.",
          "<b>Why am I not active in dev communities?</b> <br/>Not any specific reason, but I haven’t been to any hackathon, I belong to that time (specifically my college), where we brought the culture of competitive programming that too in the 3rd year of my degree. I think recent times have brought more opportunities to students like me who were not exposed to the reality of the industry. (I am now working on making it possible for students like me who are capable but don’t have resources)",
        ],
      },
      { type: "sh", data: "Reaching out" },
      {
        type: "p",
        data: "I wasn’t actively looking out for any opportunity at that time and one afternoon I connected with one Google recruiter on LinkedIn. I sent her the message asking for any openings for SWE (Frontend) specifically and also mentioned that I am passively looking for job opportunities.",
      },
      {
        type: "sq",
        data: "<b>“This works out brilliantly, just one out of 100 times maybe.”</b>",
      },
      {
        type: "p",
        data: "If you cannot get a referral, reach out to recruiters or the people who can refer you. Mostly, people vouch for their known ones and I was having no one in my friends or professional circle. (Wondering? Yes, I am the first one).",
      },
      {
        type: "p",
        data: "Well, to my surprise, she told me that one of her team members will reach out to me. And, in fact, I received a call from one of her team members before she texted me. <b>(surprise, surprise, surprise)</b>.",
      },
      { type: "ssh", data: "QnA" },
      {
        type: "ul",
        data: [
          "<b>Does getting an interview through referral makes a difference? </b> <br /> Apart from just getting preference over others (in terms of faster process), later if you clear your interviews, you get asked to provide references (googler whom you know and who knows you). And it does make a difference at that time. So, it’s always better to keep connecting with people and get a referral.",
        ],
      },
      { type: "sh", data: "Introductory Call" },
      {
        type: "p",
        data: "I received the call from the recruiter around 4 PM and it went for around 40–45 minutes. This call revolves around what one should expect in interviews and more of her part on understanding how I could be a good fit for the role she was looking for. During that call, I learned that even if that was a Frontend role, the interview process will follow almost the same path (will discuss it later). After explaining the process, she helped me with a lot of insights about preparation and approaches.",
      },
      {
        type: "q",
        data: "Interesting fact: While interviewing at Google, you can ask for as much time as you need to prepare.",
      },
      {
        type: "p",
        data: "For me, it was like starting a journey to achieving a dream, and that call too was like a mission completed <b>(getting shortlisted and landing at an interview).</b>",
      },
      { type: "ssh", data: "QnA" },
      {
        type: "ul",
        data: [
          "<b>Can a recruiter ask technical questions too? (was asked by someone over LinkedIn)</b> <br />Well, yes, they can. I was amazed to know how well my recruiter was with technical terms. (That is why they are called technical recruiters).",
        ],
      },
      { type: "sh", data: "Technical Phone Screening (Knockout)" },
      {
        type: "ip",
        data: "How is it to get to play just one ball and that should knock it out of the ground?",
      },
      {
        type: "p",
        data: "My first TPS round got scheduled after 3 weeks and I felt that to be enough time (Irony? xD)",
      },
      {
        type: "p",
        data: "For the three weeks, along with the full-time job I started preparing for the interview. Now, there were two major problems for me while preparing were:",
      },
      {
        type: "ul",
        data: [
          "I have done Engineering in IT where DSA was NOT the part of the curriculum and hence it was a little difficult for me to cover up on that part in the initial stage of my career and even now sometimes it feels like I am missing something,",
          "Along with the above point, another point was my whole experience was in Frontend where DSA was less focused.",
        ],
      },
      {
        type: "p",
        data: "Arrays, strings, and objects were my strong areas thus I started with them to get confident enough to move to harder levels. I divided my three weeks like following,",
      },
      {
        type: "ul",
        data: [
          "<b>Week 1:</b> (Easy/Medium level) for Arrays, Strings, Objects, Linked Lists, Stacks, Queues,",
          "<b>Week 2:</b> Hard level problems for the above ones and Easy level for Trees and Graphs to get hung onto them,",
          "<b>Week 3:</b> Medium level problems for Trees and Graphs.",
        ],
      },
      {
        type: "p",
        data: "I solved around 40–50 problems over <b>Leetcode</b> and <b>AlgoExpert</b> in those 3 weeks. Meanwhile, I was giving 3–4 mock interviews every week to my friends. And I would say mock interviews set the tone for me. This is how mock interviews helped me,",
      },
      {
        type: "ul",
        data: [
          "managing everything in those 45 minutes,",
          "effective communication to explain my approach,",
          "writing production-level code within time,",
          "covering all edge cases and dry running the code,",
        ],
      },
      {
        type: "p",
        data: "While preparing, I watched a few preparation videos and read some blogs too. Something which I found common was <b>communicating the solution to the interviewer</b>, which I believe improved through mock interviews.",
      },
      {
        type: "q",
        data: "Interesting fact: Google emphasizes on well-being and mental health a lot, so due to any reason if you want to reschedule your interview for a later date, you can just mail it without any hesitation anytime.",
      },
      { type: "ssh", data: "QnA" },
      {
        type: "ul",
        data: [
          "<b>How hard was the screening round? (Most asked question)</b> <br/> It was somewhere around medium to a hard level question based on strings. But, remember that there is no specific end for a problem statement, it just turns into another counter-question.",
          "<b>Key takeaways from the first round for the next rounds?</b> <br/>- Communication is the <b>key</b>.</br>- Ask <b>questions</b> if you have any.</br>- Always <b>dry-run</b> your code.</br>- Try to cover as many <b>edge cases</b> as you can.</br>- Think <b>loud</b>.</br>- Involve your interviewer in your <b>thought process</b>.",
          "<b>Why I didn’t focus much on trees and graphs?</b> <br/>Short of time. Please do not take my experience as preparation advice. If I had time, I would have definitely spent my brain over them.",
        ],
      },
      { type: "sh", data: "Result?" },
      {
        type: "p",
        data: "My recruiter reached me out next day and told me that she got positive feedback. Now, it was time for the onsite interviews, but I wasn’t feeling well prepared so I asked for time and again she being super supportive up front asked me to take around 3 weeks for preparation before moving ahead.",
      },
      {
        type: "sq",
        data: "<b>Interesting fact:</b> I didn’t give my 100% while preparing for the screening round, and I kept telling myself that if I clear this, I am going to nail all coming rounds to get to Google. xD xD Funny enough, I did.",
      },
      { type: "sh", data: "Onsite Rounds (Virtual)" },
      {
        type: "p",
        data: "So generally onsite happens on the same day or in a couple of days. Set of 5–6 interview rounds, one after the other. But in my case, I had one round each day considering the fact that I was leading the team at my current company and I had told the recruiter about my commitments already.",
      },
      {
        type: "p",
        data: "As I applied for SWE Frontend, for me there were 3 DSA rounds and 1 Frontend round, and 1 Googleyness round. DSA rounds again revolved around strings, arrays, and objects. There was no specific system design round for me but it was covered in the Frontend round. In the Frontend round, I was asked 2 different questions which actually involved DSA in Frontend. The latter was to implement a feature and high-level design for the same. (It was different from the usual System Design round).",
      },
      {
        type: "q",
        data: "Interesting fact: I interviewed around 50 people in the year 2021 for Frontend and let alone the interviewing experience helped me a lot in this journey. Tables turned quickly though!! xD",
      },
      {
        type: "p",
        data: "Googleyness round involves topics like Leadership, Time management, teamwork, and all those cultural fit things you can think of!! It is not as easy as you think and also needs preparation. It went quite smoothly for me.",
      },
      {
        type: "sq",
        data: "<b>nteresting fact:</b> My first round went on for 1 hour and 30 minutes. Interview officially got over in 45 minutes as it was supposed to be, but then discussions and QnA went for another 45 minutes. Interviewer was one of a gem person.",
      },
      { type: "ssh", data: "QnA" },
      {
        type: "ul",
        data: [
          "<b>Do DSA rounds involve frontend too in the case of the SWE Frontend profile?</b></br> It can be, or it can be not. In my case, my first DSA round was related to Frontend. It doesn’t matter to be honest, in the end, you will be writing an algorithm to solve a problem.",
          "<b>How onsite rounds were different from screening round?</b></br> Just the level up!! The screening round is a kind of trailer.",
          "<b>Does anyone got rejected because of googleyness round?</b></br> Yes, I have heard about it and one of my friends known got rejected in Googleyness round because he mentioned negative things about his last job/manager.",
          "<b>Which language did I use in the interviews?</b></br> I gave my DSA interviews in JavaScript. And my front-end interview in <b>Angular</b> and <b>React</b>.",
        ],
      },
      {
        type: "sq",
        data: "<i><b>Did I tell you?</b></br> Before my onsite rounds, I was quite nervous because I couldn’t find much resources for frontend round. For DSA, there is like huge content, videos and blogs, but for frontend round, I could hardly find anything. Then again, I reached out to my <b>superwoman recruiter</b>, and shared my problem, she told me that she will get me connected to a <b>Frontend Engineer</b> at <b>Google</b> whom they refer as <b>Champion Call</b>. I shared my concern 4–5days before my Onsite rounds and unfortunately it was New Year holidays from next day. On the same day, around at 11PM I got call from her regarding the same that there won’t be anyone available from tomorrow before my interview, so If I can, she got someone to connect at 11:30PM. I was so surprised that she helped me this far to make feel confident before my onsite interviews. I connect with one Frontend Engineer at 11:30 pm (he was located at US office) and literally thanked for all her efforts.</i>",
      },
      {
        type: "q",
        data: "A champion call is an informal chat with a google employee in your role that you can talk to about whatever is on your mind. For example, a SWE candidate can chat with a SWE in google and learn about culture, coding practice, perks, whatever else is in your mind.",
      },
      { type: "sh", data: "Result?" },
      {
        type: "p",
        data: "My recruiter was really helpful and was updating me with every possible feedback she got. I was very confident about 4 rounds and not so much confident about 1. Before my frontend round, my recruiter told me that if I didn’t get this round well, It might be the end of this process because the Frontend round has to be above good.",
      },
      {
        type: "p",
        data: "The feedback I got was “I did well in all my rounds except one DSA round, it was around average, but I did above expectations in the Frontend round so we are moving ahead with the process”. <b>All I could think of was, is this really happening.</b>",
      },
      { type: "sh", data: "Team Matching" },
      {
        type: "p",
        data: "Well, this step was really amazing because I got a chance to connect with a few brilliant minds out there on <b>Google</b>.",
      },
      {
        type: "sq",
        data: "<b>Interesting fact:</b> Before my frontend round, there was a team who was already interested in my profile and asked for team matching on same day. However, I decided to go ahead with Frontend round first and then team matching happened later on.",
      },
      {
        type: "p",
        data: "I had 3 team matching calls. One was from the cloud team and 2 were from the Google pay team. All of them were so fruitful because I get to know what I will be working on. Team matching calls are often discussion calls but the manager can ask questions related to your skillset and your previous questions, so be prepared for those questions.",
      },
      { type: "p", data: "I decided to go with the <b>Google Pay</b> team." },
      { type: "ssh", data: "QnA" },
      {
        type: "ul",
        data: [
          "<b>How long team matching calls can be?</b> Usually half an hour, but can be extended up to 45 minutes.",
          "<b>Things to keep in mind before team matching calls?</b> Go through your resume once. The recruiter will tell you beforehand what technology the team works on, what they are looking for in candidates, and why they showed interest in your profile. Brush up on those tech stacks once and prepare for any questions coming up to you about your previous job responsibilities.",
        ],
      },
      { type: "sh", data: "Hiring Committee" },
      {
        type: "p",
        data: "This step is a black box for me and I guess for most of the people out there. But, the impression I got through different resources is a set of people sitting together, walking through your interview packet (which includes feedback from all interview rounds, team matching feedback, previous job history, and all relevant things), and then making a decision on some parameters.",
      },
      {
        type: "q",
        data: "Interesting fact: (not so interesting) Only 1 out 3 people pass through hiring committee round.",
      },
      {
        type: "p",
        data: "My interview packet was supposed to be presented in a couple of days, and it has to be reviewed on my night (i.e. US office). So, it was like a patience test for me. Deep down I knew I will get through this, but then the nerves!!!",
      },
      { type: "sh", data: "Results" },
      {
        type: "p",
        data: "It was the 13th of January when I got this news from my recruiter. But with a little setback. I was offered L3 because of certain parameters and it was up to me if I wish to accept the offer or not.",
      },
      {
        type: "sq",
        data: "<b>Why I accepted offer for L3 even after having 5 years of experience?</b></br> Reason was very simple for me, </br>- I knew where I lacked. Coming from tier nth college, getting this opportunity specifically in Frontend, what else I could have asked for?</br>- I backed my abilities of quick learning, I knew once I get in, I will ramp up quickly,</br>- I was looking to convert to fullstack ( I have worked in BE previously), so what better place can be other than Google for this switch?</br>- More than anything, it was DREAM. And the cost was to get behind of current position for sometime.",
      },
      { type: "sh", data: "Tips and learnings" },
      {
        type: "ul",
        data: [
          "I prefer <b><u>AlgoExpert</u></b> for its application. It gives us an option to write 3 different solutions for a problem. Along with that, the video tutorial for each problem statement is just amazing. I loved learning through AlgoExpert(dot)io xD",
          "<b><u>Leetcode</u></b> for its wonderful community and broader coverage of problem statements. Discuss section is so helpful!",
          "GFG: whenever I wanted to read any concept or wanted to understand it thoroughly, I went for GeeksForGeeks.",
          "For mock interviews, Pramp and AlgoExpert again! Also, reach out to genius minds over LinkedIn and request if they can take mock interviews. (reach me out anytime).",
          "For frontend preparation, FrontendExpert is one of the promising resources I found, along with it MDN Web Docs got everything you need to look for. Some other useful pointers, </br><b>- How browser works? (Make it your best friend)</b></br><b> - What happens when you enter a URL in the browser and press enter?</b></br><b>- What happens when you click a button in the browser?</b></br><b>- Object prototyping,</b></br><b>- Modern JavaScript — Javascript.info (Covers almost everything),</b></br><b>- Accessibility matters in web</b>",
          "Frontend System Design, I found this amazing article <b>“Frontend system design guide”</b> which will give you detailed context about FE system design,",
          "Be consistent, solve one problem a day, but do solve. Schedule one mock interview in a week, but perform honestly.",
        ],
      },
      { type: "sp" },
      {
        type: "i",
        data: "https://miro.medium.com/v2/resize:fit:800/format:webp/1*GdKEI4IGrBH0MvlaO0SBgw.png",
        properties: {
          width: "400px",
          height: "400px",
        },
      },
      {
        type: "p",
        data: "In the end, it was a learning journey throughout, and it will go on for me. Learning from everything and everyone. And during this whole journey, there were few people who helped and motivated me at every step. This post will be incomplete if I do not thank them!",
      },
      {
        type: "q",
        data: "Journey was long yet amazing, and the destination was worth walking those miles",
      },
      {
        type: "ul",
        data: [
          "<b><u>Ashish Kumar</u></b> and <b><u>Jyoti Agarwal</u></b> for helping me with the mock interviews and their constant availability,",
          "<b><u>Sandeep Patel</u></b> for his sheer motivation, helping me with mocks, always believing in me, and that immense support (One of the geniuses I have come across),",
          "<b><u>Pargat Mann</u></b> for his design thinking discussions on real-world problems which helped me build context around different approaches,",
          "<b>Harsimar Kaur</b> for always keep pushing me to achieve my dreams, to go the extra mile, and never letting me give up,",
          "And all those people who contributed in some way to this journey!! Thank you.",
        ],
      },
      {
        type: "i",
        data: "https://miro.medium.com/v2/resize:fit:4800/format:webp/1*sMYCkonPgljINIGESzLpNA.jpeg",
        properties: {
          width: "600px",
          height: "800px",
        },
      },
      {
        type: "sq",
        data: "<b>PS: Calling out for any correction needs to be done, please mention in comments</b>",
      },
      {
        type: "p",
        data: "I hope I have covered it from start to end. Still, if you have any questions, reach out to me at,",
      },
      {
        type: "p",
        data: "Connect with me on LinkedIn: <b><u>Sidhanshu Monga</u></b>",
      },
      { type: "p", data: "or mail me at: <b>sidhanshumonga28@gmail.com</b>" },
      {
        type: "p",
        data: "or connect with me 1<>1 at <b>Block my calendar</b>.",
      },
      { type: "sp" },
    ],
  },
};
